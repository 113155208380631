<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="'https://api.optitmr.com/User_Pictures/' + userData.pictureurl"
          :text="userData.fullname"
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.fullname }}
      </h4>
      <b-col cols="12" md="4">
        <b-form-group :label="$t('isActive')" label-for="user-status">
          <b-form-checkbox
            v-model="userData.active"
            @change="changeUserActive(userData.active)"
          />
        </b-form-group>
      </b-col>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Name -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('name')" label-for="Name">
            <b-form-input id="Name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Sur Name -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('lastName')" label-for="Surname">
            <b-form-input id="Surname" v-model="userData.surname" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: Password -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('password')" label-for="password">
            <b-form-input
              id="password"
              v-model="userData.password"
              type="password"
            />
          </b-form-group>
        </b-col>
        <!-- Field: UserType -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('userType')" label-for="user-type">
            <v-select
              v-model="userData.usertype"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Password -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('mobile')" label-for="Phone">
            <b-form-input id="Phone" v-model="userData.mobile" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateUser(userData)"
    >
      {{ $t("save") }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      typeOptions: [
        { label: this.$t("admin"), value: 1 },
        { label: this.$t("manager"), value: 2 },
        { label: this.$t("user"), value: 3 },
      ],
    };
  },

  methods: {
    async updateUser(user) {
      var result = await this.$store.dispatch("usersModule/updateUser", user);
      if (result == "OK")
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("user") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("user") }),
          },
        });
    },
    async changeUserActive(active) {
      var activeInt = active ? 1 : 0;
      var userId = this.$router.currentRoute.params.id;
      var result = await this.$http.put(
        "/Users/UpdateUserActive/" + userId,
        JSON.stringify(activeInt),
        {
          headers: {
            companyid: getUserData().companyid.toString(),
            useridtoselect: userId,
          },
        }
      );
      if (result.data.status == "OK")
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("user") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("user") }),
          },
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
