<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        {{ $t("xErrorFetch", { value: $t("user") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("user") }) }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }" />
      </div>
    </b-alert>

    <b-tabs v-if="userData" active pills>
      <!-- Tab: Information -->
      <b-tab>
        <template #title active>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("account") }}</span>
        </template>
        <user-edit-tab-information
          :key="userData.id"
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <!-- Tab: Account -->
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("plants") }}</span>
        </template>
        <user-edit-tab-plant
          :key="userData.id"
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import UserEditTabPlant from "./UserEditTabPlant.vue";
import { mounted } from "vue-echarts";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabInformation,
    UserEditTabPlant,
  },
  data: function () {
    return {
      userData: {},
    };
  },
  async mounted() {
    var userdata = await store.dispatch("usersModule/fetchUser", {
      id: router.currentRoute.params.id,
    });
    this.userData = Object.assign({}, userdata);
  },
};
</script>

<style></style>
